import request from "@/utils/request";

/**
 * 上传文件
 */
export const uploadFileApi = (file) => {
  const data = new FormData();
  data.set("file", file);
  return request.post({
    url: "storage/upload",
    data,
    header: {
      ContentType: "multipart/form-data",
    },
  });
};

/**
 * base64格式上传
 * base64: string,
 *   filename: string = "avatar",
 *   suffix: string = "png"
 */
export const uploadBase64Api = (data) => {
  return request.post({
    url: "storage/upload_image_base64",
    data,
  });
};
