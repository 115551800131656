import request from "@/utils/request";

/**
 * 获取主页展示信息
 */
export const homeInfoApi = (data) => {
  return request.get({
    url: "home/index",
    data,
  });
};

/**
 * 跑步机排行
 */
export const stepRankApi = (options) =>
  request.get({
    url: "home/newCharts",
    data: options,
  });

/**
 * 入驻条例
 */
export const joinRulesApi = () => {
  return request.get({
    url: "sys_config/join_rules",
  });
};
/**
 * 关于我们
 */
export const aboutUsApi = () => {
  return request.get({
    url: "sys_config/aboutUs",
  });
};
/**
 * 联系我们
 */
export const contactUsApi = () => {
  return request.get({
    url: "sys_config/contact_us",
  });
};
/**
 * 首页广告配置
 */
export const pcAdApi = () => {
  return request.get({
    url: "sys_config/pc_ad",
  });
};
/**
 * 测试
 */
export const testApi = (data) => {
  return request.post({
    url: "home/test",
    data,
  });
};
/**
 * 获取寄语排行
 * @param data 分页参数
 * @returns 分页排行列表
 */
export const getSendWorkApi = (data) => {
  return request.get({
    url: "home/getSendWord",
    data,
  });
};
/**
 * 点赞
 * @param id 寄语id
 * @returns
 */
export const LikeSendWorkApi = (id) => {
  return request.post({
    url: `home/likeSendWord?id=${id}`,
  });
};
/**
 * 发表评论
 * @param id 寄语id
 * @param content 发表内容
 * @returns
 */
export const sendMsgApi = (id, content) => {
  return request.post({
    url: `home/addReview?id=${id}&content=${content}`,
  });
};
/**
 * 根据寄语id查询评论详情列表
 * @param data 分页参数
 * @returns
 */
export const MsgListApi = (data) => {
  return request.get({
    url: `home/getReview`,
    data,
  });
};
/**
 * 获取寄语详情
 * @param id 寄语id
 * @returns 发表寄语的详细信息
 */
export const msgDetails = (id) => {
  return request.get({
    url: `home/getReviewInfo?id=${id}`,
  });
};
/**
 * 友情链接
 */
export const friendlyLinkApi = () => {
  return request.get({
    url: "sys_config/friendlyLink",
  });
};
