import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import '@/assets/icons';
import "./element-variables.scss";
Vue.config.productionTip = false;
Vue.use(ElementUI);

function setRem() {
  const html = document.documentElement;
  const width = html.clientWidth;
  const rem = width / 1920;
  html.style.fontSize = rem + "rem";
}
function handleResize() {
  if (window.innerWidth < 1248) {
    setRem();
  } else {
    // document.documentElement.style.fontSize = "";
    // setRem();
  }
}
window.onload = handleResize;
window.onresize = handleResize;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
