import axios from "axios";
import router from "@/router";
import { Message } from "element-ui";
import { RequestCodeEnum } from "@/config";

const servers = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 10000, // 请求超时时间
  headers: {
    "Content-Type": "application/json",
    // "x-yk-mall-token": localStorage.getItem("mall_token") || localStorage.getItem("token") || "",
  },
});
servers.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("mall_token");
    if (token) {
      config.headers["x-yk-mall-token"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
servers.interceptors.response.use(
  (response) => {
    // return response.data;
    // console.log(response);
    const { errno, data, errmsg } = response.data;
    switch (errno) {
      case RequestCodeEnum.SUCCESS:
        return response.data;
      case RequestCodeEnum.ORDER_OVER:
        return response.data;
      case RequestCodeEnum.FAILED:
        Message.error(errmsg);
        return Promise.reject(errmsg);
      case RequestCodeEnum.SYSTEM_ERROR:
        return Promise.reject(errmsg);
      case RequestCodeEnum.NO_LOGIN:
        router.replace("/login");
        return Promise.reject(errmsg);
      case RequestCodeEnum.TOKEN_INVALID: // Token 过期 | 登录失效
        Message.error("请先登录后再试");
        router.replace("/login");
        return Promise.reject();
      case RequestCodeEnum.NO_BIND_PHONE:
        Message.error("您还没有绑定手机号");
        router.replace("/user");
        return Promise.reject();
      case RequestCodeEnum.NO_BIND_PAY_PW:
        Message.error("您还没有绑定密码");
        router.replace("/user");
        return Promise.reject();
      default:
        Message.error(errmsg);
        return Promise.reject(errmsg);
    }
  },
  (err) => {
    Message.error(err.message);
    return Promise.reject(err);
  }
);
const request = {
  post: (config = { url: "", data: {} }) => {
    return servers.post(config.url, config.data);
  },
  get: (config = { url: "", data: {} }) => {
    return servers.get(config.url, {
      params: config.data,
    });
  },
};
export default request;
