import request from "@/utils/request"

/**
 * 获取最小提单数量
 */
export const getMinNumApi = () => {
    return request.get({
        url: "sys_config/prize_config"
    })
}

/**
 * 获取最小提单数量
 */
export const exchangeSubmitApi = (data) => {
    return request.post({
        url: "laundry/order/prize_goods_submit",
        data
    })
}

/**
 * 获取用户剩余未提蛋列表
 */
export const getSurplusEggApi = () => {
    return request.get({
        url: "account/userPrize",
    })
}

