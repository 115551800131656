<template>
  <div class="footer">
    <div class="footer-top">
      <router-link to="/index"> 首页 </router-link>
      <div class="right">
        <el-input
          placeholder="请输入关键词"
          prefix-icon="el-icon-search"
          v-model="keyword"
          clearable
          @keyup.enter="handleSearch"
          @clear="handleSearch"
        />
        <el-button
          icon="el-icon-search"
          circle
          @click="handleSearch"
        ></el-button>
      </div>
    </div>
    <div class="center">
      <div class="left">
        <div>
          <router-link
            v-for="(item, index) in leftTop"
            :to="item.url"
            :key="index"
          >
            {{ item.label }}
          </router-link>
        </div>
        <div class="concat">
          <router-link
            v-for="(item, index) in leftConcat"
            :to="item.url"
            :key="index"
          >
            {{ item.label }}
          </router-link>
        </div>
        <div class="friendshipLink">
          <span>友情链接：</span>
          <el-link
            :underline="false"
            v-for="(item, index) in friendshipLink"
            :href="item.url"
            :key="index"
            target="_blank"
            >{{ item.name }}</el-link
          >
        </div>
      </div>
      <div class="right">
        <div class="call">
          <div style="display: flex; align-items: center">
            <i class="el-icon-phone-outline"></i
            ><span class="hotline">服务热线：</span>
          </div>
          <div class="phone">
            <p>400-677-2204</p>
            <p>0817-2269292</p>
          </div>
        </div>
        <div class="call">
          <div style="display: flex; align-items: center">
            <i class="el-icon-time"></i><span class="hotline">服务时间：</span>
          </div>
          <div class="phone">
            <p>夏周一到周六8:00 ~ 18:00</p>
            <p>冬周一到周六8:30 ~ 17:30</p>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="toplink">
        <div
          v-for="(item, index) in footerBottm"
          :key="index"
          style="display: flex; flex-direction: column; align-items: center"
          class="icons"
        >
          <img :src="item.icon" alt="" />
          <el-link :underline="false" :href="item.url" target="_blank">{{
            item.label
          }}</el-link>
        </div>
      </div>
      <div class="beian" v-html="copyright"></div>
    </div>
  </div>
</template>

<script>
import utils from "@/utils";
import { friendlyLinkApi } from "@/api/home";
import { getSecurityFilingApi } from "@/api";

export default {
  name: "PcShoppingFooter",

  data() {
    return {
      footerValue: "",
      leftTop: [
        { label: "入住条例", url: "/other/entry-rules" },
        { label: "行业新闻", url: "/news" },
        { label: "平台公告", url: "/notice" },
        { label: "帮助中心", url: "/help" },
      ],
      leftConcat: [
        { label: "API接口", url: "/" },
        { label: "联系我们", url: "/other/contact-us" },
        { label: "投诉建议", url: "/other/complaints" },
      ],
      friendshipLink: [],
      footerBottm: [
        {
          label: "全国12315互联网平台",
          url: "",
          icon: "https://yikerenyang.oss-cn-beijing.aliyuncs.com/tcv35xo18hlv9ookn8hf.png",
        },
        {
          label: "互联网不良与违法信息举报",
          url: "",
          icon: "https://yikerenyang.oss-cn-beijing.aliyuncs.com/p2btlgjs3je4ehc17iht.png",
        },
        {
          label: "网络举报APP下载",
          url: "",
          icon: "https://yikerenyang.oss-cn-beijing.aliyuncs.com/a5mw1dnq8m3dn0pd66gb.png",
        },
      ],
      keyword: "",
      urlBase: utils.getUrlBase(),
      copyright: "",
    };
  },

  mounted() {
    this.getFriendlyLinkApi();
    this.loadGetSecurityFilingApi();
  },

  methods: {
    handleSearch() {
      // this.$router.push("/search/index?keyword=" + this.keyword);
      window.location.href =
        this.urlBase + "search/index?keyword=" + this.keyword;
    },
    getFriendlyLinkApi() {
      friendlyLinkApi().then((res) => {
        this.friendshipLink = res.data ? JSON.parse(res.data) : [];
      });
    },
    loadGetSecurityFilingApi() {
      getSecurityFilingApi().then((res) => {
        this.copyright = res.data.replace(/<[^>]*>/g, "");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  // padding: 0 60px;
  box-sizing: border-box;
  font-size: 12px;

  .footer-top {
    height: 80px;
    padding: 0 60px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 2px solid #f7f7f7;
    border-bottom: 2px solid #f7f7f7;
    box-sizing: border-box;

    a {
      color: #3d3d3d !important;
      font-size: 14px;
    }

    .el-input {
      width: 260px;
      margin-right: 15px;
    }
  }

  .center {
    width: 100%;
    // height: 210px;
    padding: 30px 60px 30px 60px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    // border: 1px solid;
    .left {
      min-width: 0;
      flex: 1;

      a {
        margin-right: 40px;
        font-size: 12px !important;

        &:nth-last-child(1) {
          margin-right: 0;
        }
      }

      .concat {
        margin-top: 20px;
        margin-bottom: 10px;

        a {
          margin-right: 0;
          padding: 0 15px;
          border-right: 2px solid #333;

          &:nth-child(1) {
            padding-left: 0;
          }

          &:nth-last-child(1) {
            padding-right: 0;
            border-right: none;
          }
        }
      }

      .friendshipLink {
        span {
          font-size: 12px;
        }

        a {
          margin-right: 10px;
        }
      }
    }

    .right {
      min-width: 0;
      flex: 1;
      display: flex;

      .call {
        color: #999;
        margin-right: 100px;

        &:nth-last-child(1) {
          margin: 0;
        }

        .el-icon-phone-outline,
        .el-icon-time {
          font-size: 20px;
        }

        .hotline {
          margin-left: 5px;
        }
      }

      .phone {
        font-size: 16px;
        color: #3d3d3d;
      }
    }
  }

  .bottom {
    width: 100%;
    padding: 30px 60px;
    background-color: #50af6e;
    box-sizing: border-box;

    .toplink {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;

      .icons {
        margin-right: 80px;
        .el-link {
          color: #fff;
          font-size: 12px;
        }
        img {
          width: 30px;
          height: 30px;
          margin-bottom: 10px;
        }

        &:nth-last-child(1) {
          margin-right: 0;
        }
      }
    }

    .beian {
      text-align: center;
      color: #fff;
    }
  }
}
</style>
