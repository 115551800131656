import dayjs from "dayjs";

const utils = {
  dateFormat: {
    toYYYYMMDD: function (val) {
      return dayjs(val || "").format("YYYY-MM-DD");
    },
  },
  getUrlBase() {
    return process.env.NODE_ENV === "production" ? "/" : "/pcshopping/";
    // return "/";
  },
  getStoreBase() {
    return process.env.VUE_APP_STORE_URL;
  },
};

export default utils;
