import request from "@/utils/request";

/**
 * 获取直播地址
 */
export const getLiveApi = (deviceId) => {
  return request.get({
    url: "device/get_live",
    data: {
      deviceId,
    },
  });
};

/**
 * 云台控制
 */
export const deviceCloudCntrolApi = (data) => {
  return request.post({
    url: "device/cloud_control",
    data,
  });
};

/**
 * 获取云台控制token
 */
export const cloudControlTokenApi = () => {
  return request.get({
    url: "device/get_token",
  });
};
