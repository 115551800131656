import request from "@/utils/request";

/**
 * 新增 / 修改 收货地址
 */
export const saveOrEditAddrApi = (data) => {
  return request.post({
    url: "address/save",
    data,
  });
};

/**
 * 收货地址列表
 */
export const addrListApi = () => {
  return request.get({
    url: "address/list",
  });
};

/**
 * 删除收货地址
 */
export const delAddrApi = (id) => {
  return request.post({
    url: `address/delete/${id}`,
  });
};

/**
 * 获取默认地址
 */
export const getDefaultAddrApi = () => {
  return request.get({
    url: "address/default",
  });
};
