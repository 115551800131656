import request from "@/utils/request";

/**
 * 获取宝宝信息
 */
export const getbabys = (data) => {
  return request.get({
    url: "feeding/poutry/mybaby",
    data,
  });
};
/**
 * 获取宝宝运动信息
 */
export const getbabySteps = (code) => {
  return request.get({
    url: `home/petData?code=${code}`,
  });
};
/**
 * 获取宝宝地址位置信息
 */
export const getbabyCity = (code) => {
  return request.get({
    url: `home/petLocation?latitudeAndLongitude=${code}`,
  });
};
/**
 * 我的果树
 * @param data page 页码 limit 每页条数
 * @returns
 */
export const getTreeApi = (data) => {
  return request.get({
    url: "feeding/tree/mybaby",
    data,
  });
};
/**
 * 我的菜园
 * @param data
 * @returns
 */
export const getLandApi = (data) => {
  return request.get({
    url: "feeding/land/mybaby",
    data,
  });
};
