import request from "@/utils/request";

/**
 * 活动列表
 */
export const activityListApi = (data) => {
  return request.get({
    url: "feeding/activity/list",
    data,
  });
};

/**
 * 活动详情
 */
export const activityDetailsApi = (data) => {
  return request.get({
    url: "feeding/activity/detail",
    data,
  });
};

/**
 * 活动记录
 */
export const activityRecordListApi = (data) => {
  return request.get({
    url: "feeding/activity/record",
    data,
  });
};
