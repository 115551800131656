import request from "@/utils/request";

export const storeListApi = (data) => {
  return request.get({
    url: "goods/list",
    data,
  });
};

/**
 * 商品详情
 */
export const storeDetailsApi = (data) => {
  return request.get({
    url: "goods/detail",
    data,
  });
};

/*type logsItem={
  page:number
  limit:number
  id:number
}*/
/**
 * 商城商品购买记录
 * @returns 购买记录列表
 */
export const storeBuyLogsApi = (data) => {
  return request.get({
    url: `goods/purchaseRecord`,
    data,
  });
};
