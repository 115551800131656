<template>
    <div>
        <Header />
        <main>
            <router-view />
        </main>
        <Footer />
    </div>
</template>

<script>
import Header from './Header.vue'
import Footer from './Footer.vue'

export default {
    name: 'Layout',
    components: {
        Header,
        Footer
    }
}
</script>

<style scoped lang="scss">
body{
    padding: 0;
    margin: 0;
}
/* 添加样式 */
main{
    padding: 0 130px;
    box-sizing: border-box;

}
</style>
