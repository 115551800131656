import request from "@/utils/request"


/**
 * 获取新闻列表
 */
export const newsListApi = (data) => {
  return request.get({
    url: "circle/list",
    data: data
  })
}

/**
 * 获取新闻详情
 */
export const newsInfoApi = (id) => {
  return request.get({
    url: `circle/info?id=${id}`
  })
}
/**
 * 获取认养保证文本内容
 */
export const AdoptionGuaranteeApi = () => {
  return request.get({
    url: `sys_config/adoption_guarantee`
  })
}
