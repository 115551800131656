import request from "@/utils/request";

/**
 * 投食设备 投食
 */
export const deviceFeedApi = (data) => {
  return request.post({
    url: `feeding/foods/doing`,
    data,
  });
};

/*type FeedRecordOptions = {
    page: number
    limit: number
    /!** id *!/
    feedingObjectId: number
    /!** 1 家禽 2 果树 3 土地 *!/
    feedingType:1|2|3
}*/

/**
 * 投食记录
 */
export const feedRecordApi = (data) => {
  return request.get({
    url: "feeding/foods/list",
    data,
  });
};

/**
 * 家禽共享查询
 * @param data 查询参数
 */
export const adoptAnimalsListApi = (data) => {
  return request.get({
    url: "feeding/poutry/list",
    data,
  });
};

/**
 * 搜索列表查询
 * @param data 查询参数
 */
export const searchListApi = (data) => {
  return request.get({
    url: "feeding/poutry/allList",
    data,
  });
};

/**
 * 果树共享列表
 */
export const feedingTreeListApi = (data) => {
  return request.get({
    url: "feeding/tree/list",
    data,
  });
};

/**
 * 家禽认详情
 */
export const adoptAnimalsDetailsApi = (data) => {
  return request.get({
    url: "feeding/poutry/detail",
    data,
  });
};

// 获取农场协议
export const FarmAgreementApi = (id) => {
  return request.get({
    url: `sys_config/farmAgreement?farmId=${id}`,
  });
};

/**
 * 果树共享详情
 */
export const adoptTreeDetailsApi = (data) => {
  return request.get({
    url: "feeding/tree/detail",
    data,
  });
};

/**
 * 计算家禽共享价格
 */
export const adoptCheckoutPriceApi = (data) => {
  return request.post({
    url: "feeding/poutry/checkout",
    data,
  });
};

/**
 * 计算家禽共享价格
 */
export const adoptTreeCheckoutPriceApi = (data) => {
  return request.post({
    url: "feeding/tree/checkout",
    data,
  });
};

/**
 * 土地认养列表
 */
export const adoptLandListApi = (data) => {
  return request.get({
    url: "feeding/land/list",
    data,
  });
};

/**
 * 土地认养详情
 */
export const adoptLandDetailsApi = (data) => {
  return request.get({
    url: "feeding/land/detail",
    data,
  });
};

/**
 * 计算土地认养价格
 */
export const adoptLandCheckoutApi = (data) => {
  return request.post({
    url: "feeding/land/checkout",
    data,
  });
};

/**
 * 共享商品购买记录
 * @returns 共享商品购买记录
 */
export const feedingBuyLogsApi = (data, type) => {
  return request.get({
    url: `feeding/${type}/purchaseRecord`,
    data,
  });
};
