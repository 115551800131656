import request from "@/utils/request"

/**
 * 玩法列表
 */
export const getIssueListApi = (data) => {
  return request.get({
    url: 'issue/list',
    data
  })
}


/**
 * 帮助中心
 */
export const getHelpCenterListApi = (data) => {
  return request.get({url: "helper/list", data})
}

/**
 * 帮助详情
 */
export const helperDetailsApi = (id) => {
  return request.get({
    url: 'helper/info',
    data: {
      id
    }
  })
}
