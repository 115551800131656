export const RequestCodeEnum = {
  SUCCESS: 0, //成功
  FAILED: -1, // 失败
  SYSTEM_ERROR: -2,
  NO_LOGIN: 501, // 没有登陆
  TOKEN_INVALID: 502,
  NO_BIND_PHONE: 507, // 没绑定手机号
  NO_BIND_PAY_PW: 508, // 没有绑定支付密码
  ORDER_OVER: 100009, //
};

export const newsType = {
  1: { title: "行业新闻", en: "Industry consulting", path: "news" },
  2: { title: "平台公告", en: "Notice", path: "notice" },
  3: { title: "帮助中心", en: "Help Center", path: "help" },
};

export const poultryType = {
  1: {
    title: "打工鸡",
    en: "Notice",
    path: "wage",
    nickname: "宝宝名称",
    message: "宝宝寄语",
  },
  2: {
    title: "家禽认养",
    en: "Industry consulting",
    path: "animal",
    nickname: "宝宝名称",
    message: "宝宝寄语",
  },
  3: {
    title: "共享菜园",
    en: "Share vegetable",
    path: "land",
    nickname: "种植牌",
    message: "种植寄语",
  },
  4: {
    title: "果树认养",
    en: "Tree",
    path: "fruit",
    nickname: "认领名称",
    message: "认领寄语",
  },
};
