import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/components/Layout.vue";
import LayoutUser from "@/components/LayoutUser.vue";
import store from "@/store/index";
import { Message } from "element-ui";

Vue.use(VueRouter);
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    redirect: "/index", // 添加重定向
  },
  {
    path: "/user",
    redirect: "/user/userInfo",
  },
  {
    path: "/layout",
    component: Layout,
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("@/views/index/index.vue"),
      },
      {
        path: "/activity/farm",
        name: "activityFarmIndex",
        component: () => import("@/views/activity/farm/index.vue"),
      },
      {
        path: "/buy/land",
        name: "buyLand",
        component: () => import("@/views/buy/land/index.vue"),
        meta: {
          title: "土地认养详情",
        },
      },
      {
        path: "/user_pet",
        name: "userPet",
        meta: { title: "我的宝宝" },
        component: () => import("@/views/user/pet/index.vue"),
      },
      {
        path: "/user/order-submit",
        name: "orderSubmit",
        component: () => import("@/views/submitOrder/index.vue"),
        meta: { title: "购买下单" },
      },
      {
        path: "/user/order-submit-land",
        name: "orderSubmitLand",
        component: () => import("@/views/submitOrder/land.vue"),
        meta: { title: "购买下单" },
      },
      {
        path: "/user/order-submit-fruit",
        name: "orderSubmitFruit",
        component: () => import("@/views/submitOrder/fruit.vue"),
        meta: { title: "购买下单" },
      },
      {
        path: "/user/order-submit-mall",
        name: "orderSubmitMall",
        component: () => import("@/views/submitOrder/mall.vue"),
        meta: { title: "购买下单" },
      },
      {
        path: "/user/order-submit-activity",
        name: "orderSubmitActivity",
        component: () => import("@/views/submitOrder/activity.vue"),
        meta: { title: "购买下单" },
      },
    ],
  },
  {
    path: "/animal",
    name: "animal",
    redirect: "/animal/index",
    component: Layout,
    meta: {
      title: "家禽认养",
    },
    children: [
      {
        path: "index",
        name: "animalIndex",
        component: () => import("@/views/animal/index.vue"),
        meta: {
          title: "列表",
        },
      },
      {
        path: "show",
        name: "animalShow",
        component: () => import("@/views/animal/show.vue"),
        meta: {
          title: "详情",
        },
      },
    ],
  },
  ,
  {
    path: "/wage",
    name: "wage",
    redirect: "/wage/index",
    component: Layout,
    meta: {
      title: "打工鸡",
    },
    children: [
      {
        path: "index",
        name: "wageIndex",
        component: () => import("@/views/animal/index.vue"),
        meta: {
          title: "列表",
        },
      },
      {
        path: "show",
        name: "wageShow",
        component: () => import("@/views/animal/show.vue"),
        meta: {
          title: "详情",
        },
      },
    ],
  },
  {
    path: "/land",
    name: "land",
    redirect: "/land/index",
    component: Layout,
    meta: {
      title: "共享菜园",
    },
    children: [
      {
        path: "index",
        name: "landIndex",
        component: () => import("@/views/land/index.vue"),
        meta: {
          title: "列表",
        },
      },
      {
        path: "show",
        name: "landShow",
        component: () => import("@/views/land/show.vue"),
        meta: {
          title: "详情",
        },
      },
    ],
  },
  {
    path: "/fruit",
    name: "fruit",
    redirect: "/fruit/index",
    component: Layout,
    meta: {
      title: "果树认领",
    },
    children: [
      {
        path: "index",
        name: "fruitIndex",
        component: () => import("@/views/fruit/index.vue"),
        meta: {
          title: "列表",
        },
      },
      {
        path: "show",
        name: "fruitShow",
        component: () => import("@/views/fruit/show.vue"),
        meta: {
          title: "详情",
        },
      },
    ],
  },
  {
    path: "/search",
    name: "search",
    redirect: "/search/index",
    component: Layout,
    meta: {
      title: "搜索",
    },
    children: [
      {
        path: "index",
        name: "searchIndex",
        component: () => import("@/views/search/index.vue"),
        meta: {
          title: "列表",
        },
      },
    ],
  },
  {
    path: "/mall",
    name: "mall",
    redirect: "/mall/index",
    component: Layout,
    meta: {
      title: "生态商城",
    },
    children: [
      {
        path: "index",
        name: "mallIndex",
        component: () => import("@/views/mall/index.vue"),
        meta: {
          title: "列表",
        },
      },
      {
        path: "show",
        name: "mallShow",
        component: () => import("@/views/mall/show.vue"),
        meta: {
          title: "详情",
        },
      },
    ],
  },
  {
    path: "/news",
    name: "news",
    redirect: "/news/index",
    component: Layout,
    meta: {
      title: "行业新闻",
    },
    children: [
      {
        path: "index",
        name: "newsIndex",
        component: () => import("@/views/news/index.vue"),
        meta: {
          title: "列表",
        },
      },
      {
        path: "show",
        name: "newsShow",
        component: () => import("@/views/news/show.vue"),
        meta: {
          title: "详情",
        },
      },
    ],
  },
  {
    path: "/help",
    name: "help",
    redirect: "/help/index",
    component: Layout,
    meta: {
      title: "帮助中心",
    },
    children: [
      {
        path: "index",
        name: "helpIndex",
        component: () => import("@/views/help/index.vue"),
        meta: {
          title: "列表",
        },
      },
      {
        path: "show",
        name: "helpShow",
        component: () => import("@/views/help/show.vue"),
        meta: {
          title: "详情",
        },
      },
    ],
  },
  {
    path: "/notice",
    name: "notice",
    redirect: "/notice/index",
    component: Layout,
    meta: {
      title: "平台公告",
    },
    children: [
      {
        path: "index",
        name: "noticeIndex",
        component: () => import("@/views/notice/index.vue"),
        meta: {
          title: "列表",
        },
      },
      {
        path: "show",
        name: "noticeShow",
        component: () => import("@/views/notice/show.vue"),
        meta: {
          title: "详情",
        },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index.vue"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/login/register",
    name: "loginRegister",
    component: () => import("@/views/login/register.vue"),
    meta: {
      title: "注册",
    },
  },
  {
    path: "/login/forgot",
    name: "loginForgot",
    component: () => import("@/views/login/forgot.vue"),
    meta: {
      title: "找回密码",
    },
  },
  /*{
    path: "/login",
    name: "login",
    redirect: "/login/index",
    component: Layout,
    meta: {
      title: "登录",
    },
    children: [
      {
        path: "index",
        name: "loginIndex",
        component: () => import("@/views/login/index.vue"),
        meta: {
          title: "用户登录",
        },
      },
      {
        path: "register",
        name: "loginRegister",
        component: () => import("@/views/login/register.vue"),
        meta: {
          title: "用户注册",
        },
      },
    ],
  },*/
  {
    path: "/user_adopt",
    component: LayoutUser,
    meta: { title: "我的认养", icon: "user-my" },
    children: [
      {
        path: "order-working",
        name: "orderWorking",
        meta: { title: "打工鸡" },
        component: () => import("@/views/user/order-working.vue"),
      },
      {
        path: "order-poultry",
        name: "orderPoultry",
        meta: { title: "家禽认养" },
        component: () => import("@/views/user/order-poultry.vue"),
      },
      {
        path: "order-land",
        name: "orderLand",
        meta: { title: "共享菜园" },
        component: () => import("@/views/user/order-land.vue"),
      },
      {
        path: "order-tree",
        name: "orderTree",
        meta: { title: "果树认领" },
        component: () => import("@/views/user/order-tree.vue"),
      },
    ],
  },
  {
    path: "/user_order",
    component: LayoutUser,
    meta: { title: "我的订单", icon: "user-order" },
    children: [
      {
        path: "order-mall",
        name: "orderMall",
        meta: { title: "商城订单" },
        component: () => import("@/views/user/order-mall.vue"),
      },
      {
        path: "order-activity",
        name: "orderActivity",
        meta: { title: "活动订单" },
        component: () => import("@/views/user/order-activity.vue"),
      },
      /* {
        path: "order-exchange",
        name: "orderExchange",
        meta: { title: "兑换订单" },
        component: () => import("@/views/user/order-exchange.vue"),
      },*/
      {
        path: "order-winning",
        name: "orderWinning",
        meta: { title: "中奖订单" },
        component: () => import("@/views/user/order-winning.vue"),
      },
    ],
  },
  {
    path: "/user_often",
    component: LayoutUser,
    meta: { title: "常用功能", icon: "user-other" },
    children: [
      {
        path: "collection",
        name: "oftenCollection",
        meta: { title: "我的收藏" },
        component: () => import("@/views/often/collection.vue"),
      },
    ],
  },
  {
    path: "/user",
    component: LayoutUser,
    meta: { title: "其他信息", icon: "user-other" },
    children: [
      {
        path: "userInfo",
        name: "userInfo",
        meta: { title: "会员中心", hidden: true },
        component: () => import("@/views/user/index.vue"),
      },

      {
        path: "profile",
        name: "userProfile",
        meta: { title: "账号资料" },
        component: () => import("@/views/user/profile/index.vue"),
      },
      /* {
        path: "phone",
        name: "phone",
        meta: { title: "更改手机号" },
        component: () => import("@/views/user/profile/phone.vue"),
      },
      {
        path: "order-express",
        name: "orderExpress",
        meta: { title: "查看物流" },
        component: () => import("@/views/user/order-express.vue"),
      },
      {
        path: "rules",
        name: "rules",
        meta: { title: "玩法介绍" },
        component: () => import("@/views/user/rules.vue"),
      },*/
    ],
  },

  {
    path: "/other",
    name: "other",
    redirect: "/index",
    component: Layout,
    meta: {
      title: "其他信息",
    },
    children: [
      {
        path: "entry-rules",
        name: "entryRules",
        component: () => import("@/views/other/entry-rules.vue"),
        meta: {
          title: "入住条例",
        },
      },
      {
        path: "about-us",
        name: "aboutUs",
        component: () => import("@/views/other/about-us.vue"),
        meta: {
          title: "关于我们",
        },
      },
      {
        path: "contact-us",
        name: "contact-us",
        component: () => import("@/views/other/contact-us.vue"),
        meta: {
          title: "联系我们",
        },
      },
      {
        path: "complaints",
        name: "complaints",
        component: () => import("@/views/other/complaints.vue"),
        meta: {
          title: "投诉建议",
        },
      },
      ,
      {
        path: "adoption-uarantee",
        name: "adoptionUarantee",
        component: () => import("@/views/other/adoption_uarantee.vue"),
        meta: {
          title: "认养保障",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  console.log(to.path);
  if (!localStorage.getItem("mall_token")) {
    //临时token
    localStorage.setItem(
      "token",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0aGlzIGlzIGxpdGVtYWxsIHRva2VuIiwiYXVkIjoiTUlOSUFQUCIsImlzcyI6IkxJVEVNQUxMIiwiZXhwIjoxNzMzMjE1ODQ4LCJ1c2VySWQiOjE1LCJpYXQiOjE3MzI2MTEwNDh9.UxFI8OOzTUea8WUJvuTpdWGieTryYnCtL5XbCFXeub4"
    );
  }

  const _title_arr = to.matched
    .map((item) => {
      return item.meta ? item.meta?.title : "";
    })
    .filter((item) => item)
    .filter((item) => item != "列表" && item != "详情");
  document.title = _title_arr.reverse().join("-") + "   一颗认养" || "一颗认养";

  //判断登录
  let token;
  if (store.getters.token) {
    token = store.getters.token;
  } else {
    token = localStorage.getItem("mall_token");
    if (token) {
      await store.commit("SETTOKEN", token);
    }
  }

  if (!store.getters.userInfo.id) {
    let userInfo = localStorage.getItem("mall_userInfo");
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
      await store.commit("SETUSERINFO", userInfo);
    }
  }

  if (to.path.startsWith("/mall/show") && !token) {
    Message.error("请登录");
    next("/login");
    return;
  }

  if (to.path.startsWith("/user") && !token) {
    next("/login");
    return;
  }

  window.scrollTo(0, 0); //返回页面顶部
  next();
});

export default router;
